import React, { useEffect, useContext } from "react"
import Layout from '../components/layout'
import SEO from '../components/SEO'
import Jumbotron from "../components/jumbotron/Jumbotron"
import DealerArray from "../components/dealerArray/DealerArray"
import WrappedFindDealerMobile from "../components/findDealer/wrappedFindDealerMobile"


export default function ServiceTemplate(props) {
  const { pageContext } = props
  const { pageContent, dealerInfo } = pageContext

  const components = [
    <SEO key={"SEO"} title={"Contact"} data={pageContent.SEO} />,
    <Jumbotron key={"Jumbotron"} data={{ ...pageContent.Jumbotron }} />,
    <WrappedFindDealerMobile pageContent={pageContent} pageContext={pageContext}
      dealerInfo={dealerInfo} data={{ order: 2, visible: true }} />,
    <DealerArray dealers={pageContext.dealers} data={{ order: 3, visible: true }}
    />
  ]

  const sortedComponents = components.sort((a, b) =>
    parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
  )
  const visibleComponents = sortedComponents.filter(component => {
    return component.props.data.visible === true
  })

  return (
    <Layout>
      {visibleComponents}
    </Layout>
  )
}
